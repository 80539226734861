import { Children, type PropsWithChildren } from "react";

import { cx } from "classix";

import CollapseToggleIcon from "@rls/icons/chevron-down";
import { Collapse, Text } from "@rls/ui-kit";

import * as styles from "./SidebarMenuCollapseContent.css";

export interface SidebarMenuCollapseContentProps extends PropsWithChildren {
  text: string;
  initiallyExpanded?: boolean;
  headlineClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
  triggerClassname?: string;
}

export default function SidebarMenuCollapseContent({
  text,
  initiallyExpanded,
  headlineClassName,
  contentClassName,
  wrapperClassName,
  triggerClassname,
  children,
}: SidebarMenuCollapseContentProps) {
  const hasChildren = Children.count(children) > 0;

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <Collapse defaultExpanded={initiallyExpanded}>
        <Collapse.Trigger asChild>
          <Text
            as="button"
            variant="navigation-primary"
            color="navigation-default"
            className={cx(styles.headline, triggerClassname)}
            hoverable
          >
            <span className={cx(styles.title, headlineClassName)}>{text}</span>
            {hasChildren && (
              <Collapse.WithState
                render={({ isExpanded }) => (
                  <CollapseToggleIcon
                    className={styles.collapse({ expanded: isExpanded })}
                  />
                )}
              />
            )}
          </Text>
        </Collapse.Trigger>

        {hasChildren && (
          <Collapse.Content>
            <div className={cx(styles.content, contentClassName)}>
              {children}
            </div>
          </Collapse.Content>
        )}
      </Collapse>
    </div>
  );
}
