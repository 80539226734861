import { Suspense, useEffect } from "react";

import { Outlet } from "react-router-dom";

// import Footer from "./footer/Footer";
import Header from "./header/Header";

import * as styles from "./BaseLayout.css";
import useAuthStore from "@/stores/auth";

export default function BaseLayout() {
  const { user } = useAuthStore();
  
  useEffect(() => {
    if (user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'userId': user.id,
      });
    }
  }, [user]);

  return (
    // <Suspense>
    <div className={styles.page}>
      <Suspense>
        <Header />
      </Suspense>
      <div className={styles.main}>
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
      {/* <Footer /> */}
    </div>
    // </Suspense>
  );
}
