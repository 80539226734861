import { cx } from "classix";
import { generatePath, useParams } from "react-router-dom";

import { Text } from "@rls/ui-kit";

import Paths from "@/constants/paths";
import MenuLink from "@/layouts/menu/default/link/MenuLink";
import { useActiveTheme } from "@/stores/theme";

import TaskStatusIcon, {
  type TaskStatusIconProps,
} from "./status-icon/TaskStatusIcon";

import * as styles from "./MenuTask.css";

export interface MenuTaskProps {
  id: number;
  status: TaskStatusIconProps["status"];
  name: string;
}

export default function MenuTask({ id, status, name }: MenuTaskProps) {
  const { course = "", task = "" } = useParams();

  const theme = useActiveTheme();

  const link = generatePath(Paths.COURSE_TASK, {
    course,
    task: String(id),
  });

  const taskButtonActiveClassname =
    theme === "dark"
      ? styles.task({ variant: "activeDark" })
      : styles.task({ variant: "activeLight" });

  const isActive = id === Number(task);

  return (
    <Text
      as={MenuLink}
      to={link}
      variant="navigation-secondary"
      color="custom"
      className={cx(
        styles.task({ variant: status ?? "available" }),
        isActive && taskButtonActiveClassname
      )}
    >
      <span className={styles.text}>{name}</span>
      <TaskStatusIcon status={status} />
    </Text>
  );
}
