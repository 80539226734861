import * as Sentry from "@sentry/react";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { User } from "@/schemas/user";

import { useUserSelectedCohortStore } from "../user-selected-cohort";
import { useVideosDisplayStore } from "../videos-display-type";

export interface AuthState {
  _auth: string | null | undefined;
  user: User | null;

  login: (token: string, user: User) => void;
  logout: () => void;

  isLoggedIn: () => boolean;
}

const initialState: Pick<AuthState, "_auth" | "user"> = {
  _auth: null,
  user: null,
};

const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      ...initialState,

      login: (token, user) => {
        set({ _auth: token, user });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'eCategory': 'userId',
          'eAction': 'sendUserId',
          'userId': user.id,
          'event': 'GAEventUserId'
        });          

        Sentry.setUser({
          id: user.id,
        });
      },
      logout: () => {
        useUserSelectedCohortStore.getState().reset();
        useVideosDisplayStore.getState().reset();

        set(initialState);

        Sentry.setUser(null);
      },

      isLoggedIn: () => get()._auth !== null,
    }),
    {
      name: "auth-store",
    }
  )
);

export default useAuthStore;

export const useIsLoggedIn = () => useAuthStore((state) => state.isLoggedIn());
