import cx from "classix";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import { useIsNotPaid } from "@/features/course-menu/hooks/useIsNotPaid";
import useIsCohortSuperUser from "@/features/course-task/hooks/useIsCohortSuperUser";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";
import type { CourseMenuTaskModule } from "@/schemas/course/menu/task-module";
import { useActiveTheme } from "@/stores/theme";
import type { ExtendedCourseMenuTaskModule } from "@/utils/task-modules/transform";
import { isModule } from "@/utils/task-modules/type";

import MenuTaskEmpty from "../../../task/empty/MenuTaskEmpty";
import MenuTask from "../../../task/MenuTask";

import * as styles from "./ProgramModule.css";

export interface CourseMenuSectionProgramModuleProps
  extends Pick<
    ExtendedCourseMenuTaskModule,
    "name" | "number" | "tasks" | "type"
  > {
  isEnabled: CourseMenuTaskModule["is_enabled"];
}

export default function CourseMenuSectionProgramModule({
  name,
  tasks,
  isEnabled,
  number,
  type,
}: CourseMenuSectionProgramModuleProps) {
  const { task = "" } = useParams();

  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_TASKS,
  });

  const isNotPaid = useIsNotPaid();

  const isSuperUser = useIsCohortSuperUser();

  const theme = useActiveTheme();

  const isTopic = type === "topic";

  const moduleTriggerButtonActiveClassname =
    theme === "dark"
      ? styles.moduleTriggerButtonActiveDark
      : styles.moduleTriggerButtonActiveLight;

  const isContainTask = (
    tasks: CourseMenuSectionProgramModuleProps["tasks"]
  ): boolean => {
    return tasks.some((t) =>
      isModule(t) ? isContainTask(t.tasks) : t.id === Number(task)
    );
  };

  const isActive = isContainTask(tasks);

  return (
    <SidebarMenu.Collapse
      headlineClassName={cx(styles.module, isTopic && styles.topic)}
      text={t(isTopic ? "topic" : "module", {
        number,
        name,
      })}
      disabled={isNotPaid || (!isSuperUser && !isEnabled)}
      wrapperClassName={isTopic ? styles.topicWrapper : undefined}
      triggerClassname={cx(
        styles.moduleTriggerButton,
        isActive && moduleTriggerButtonActiveClassname
      )}
    >
      {tasks.length === 0 ? (
        <MenuTaskEmpty>{t("empty")}</MenuTaskEmpty>
      ) : (
        tasks.map((task) => {
          if (isModule(task)) {
            const {
              id,
              name,
              tasks,
              is_enabled: isEnabled,
              number,
              type,
            } = task;

            return (
              <CourseMenuSectionProgramModule
                key={id}
                name={name}
                tasks={tasks}
                isEnabled={isEnabled}
                number={number}
                type={type}
              />
            );
          }

          const { id, name, number, status } = task;

          return (
            <MenuTask
              key={id}
              id={id}
              status={status}
              name={t("task", {
                number,
                name,
              })}
            />
          );
        })
      )}
    </SidebarMenu.Collapse>
  );
}
